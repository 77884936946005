// mui
import { Shadows, createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';

interface StyleOverridesParams {
	theme: Theme;
}

const brand = {
	blue: {
		100: '#F6FCFF',
		200: '#E9F7FD',
		300: '#CDE7F9',
		400: '#009FDA',
		500: '#0071BC',
		600: '#00538A',
		700: '#004370',
		800: '#002244',
	},
	green: {
		100: `#EDF8F3`,
		200: `#C9EBDB`,
		300: `#A5DDC3`,
		400: `#70C9A0`,
		500: `#4CBB88`,
		600: `#33A16F`,
		700: `#006F3C`,
		800: `#003C09`,
	},
	grey: {
		100: `#FAFAFA`,
		200: `#F2F2F2`,
		300: `#E6E6E6`,
		400: `#CCCCCC`,
		500: `#666666`,
		600: `#4D4D4D`,
		700: `#262626`,
		800: `#1A1A1A`,
	},
	magenta: {
		100: `#FFE8F7`,
		200: `#FFD2EE`,
		300: `#FEA4DD`,
		400: `#FE77CC`,
		500: `#FD1CAA`,
		600: `#CA0077`,
		700: `#970044`,
		800: `#640011`,
	},
	orange: {
		100: `#FDEEEB`,
		200: `#FBDDD8`,
		300: `#F7BBB0`,
		400: `#F07761`,
		500: `#EC553A`,
		600: `#D23C21`,
		700: `#9F0900`,
		800: `#6D0000`,
	},
	purple: {
		100: `#F0DFFF`,
		200: `#E0BEFF`,
		300: `#D19EFE`,
		400: `#C17DFE`,
		500: `#B25DFE`,
		600: `#9844E4`,
		700: `#7F2ACB`,
		800: `#4C0098`,
	},
	teal: {
		100: `#E6F5F7`,
		200: `#CCEAEE`,
		300: `#9AD6DE`,
		400: `#67C1CD`,
		500: `#0298AC`,
		600: `#006579`,
		700: `#004C60`,
		800: `#003246`,
	},
	yellow: {
		100: `#FFF8E7`,
		200: `#FEE9B8`,
		300: `#FECD5B`,
		400: `#FDB714`,
		500: `#E39D00`,
		600: `#B06B00`,
		700: `#7E3800`,
		800: `#4B0500`,
	},
};

const primary = {
	contrastText: '#ffffff',
	dark: brand.blue[800],
	light: brand.blue[100],
	main: brand.blue[500],
};

const secondary = {
	contrastText: '#ffffff',
	dark: brand.blue[800],
	light: brand.blue[200],
	main: brand.blue[800],
};

const error = {
	contrastText: '#ffffff',
	dark: brand.orange[700],
	light: brand.orange[100],
	main: brand.orange[600],
};

const info = {
	contrastText: '#ffffff',
	dark: brand.blue[700],
	light: brand.blue[100],
	main: brand.blue[600],
};

const success = {
	contrastText: '#ffffff',
	dark: brand.green[700],
	light: brand.green[100],
	main: brand.green[600],
};

const warning = {
	contrastText: '#ffffff',
	dark: brand.yellow[700],
	light: brand.yellow[100],
	main: brand.yellow[600],
};

const magenta = {
	contrastText: '#ffffff',
	dark: brand.magenta[700],
	light: brand.magenta[100],
	main: brand.magenta[600],
};

const neutral = {
	contrastText: '#ffffff',
	dark: brand.grey[800],
	light: brand.grey[100],
	main: brand.grey[600],
};

const purple = {
	contrastText: '#ffffff',
	dark: brand.purple[700],
	light: brand.purple[100],
	main: brand.purple[600],
};

const teal = {
	contrastText: '#ffffff',
	dark: brand.teal[700],
	light: brand.teal[100],
	main: brand.teal[600],
};

const white = {
	contrastText: '#ffffff',
	dark: '#ffffff',
	light: '#ffffff',
	main: '#ffffff',
};

export const palette = {
	brand,
	error,
	info,
	magenta,
	neutral,
	primary,
	purple,
	secondary,
	success,
	teal,
	warning,
	white,
};

const shadows = [
	`none`,
	`0px 2px 6px 4px rgba(0, 0, 0, 0.03)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.23)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.26)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.29)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.31)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.34)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.37)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.40)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.43)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.46)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.49)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.52)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.55)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.57)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.60)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.63)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.66)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.69)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.72)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.75)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.78)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.81)`,
	`3px 3px 13px 0px rgba(0, 0, 0, 0.84)`,
	`3px 3px 40px 0px rgba(0, 0, 0, 0.40)`,
] as Shadows;

const fontFallback = 'Helvetica, Arial, sans-serif';
const fontOpenSans = `Open Sans, ${fontFallback}`;
const fontAndes = `Andes, ${fontOpenSans}`;

export const typography = {
	fontFamily: fontOpenSans,
	fontSize: 14,
	htmlFontSize: 16,
	h1: {
		fontFamily: fontAndes,
		fontSize: '2.8rem',
		fontWeight: 700,
		lineHeight: '3.8rem',
	},
	h2: {
		fontFamily: fontAndes,
		fontSize: '2rem',
		fontWeight: 700,
		lineHeight: '3rem',
	},
	h3: {
		fontFamily: fontAndes,
		fontSize: '1.6rem',
		fontWeight: 700,
		lineHeight: '2.6rem',
	},
	h4: {
		fontFamily: fontAndes,
		fontSize: '1.8rem',
		fontWeight: 700,
		lineHeight: '2.8rem',
	},
	h5: {
		fontFamily: fontAndes,
		fontSize: '1.6rem',
		fontWeight: 700,
		lineHeight: '2.6rem',
	},
	h6: {
		fontFamily: fontAndes,
		fontSize: '1.4rem',
		fontWeight: 700,
		lineHeight: '2.4rem',
	},
	body1: {
		fontSize: '1.4rem',
		fontWeight: 400,
		lineHeight: '2.4rem',
	},
	body2: {
		fontSize: '1.4rem',
		fontWeight: 500,
		lineHeight: '2.4rem',
		color: brand.grey[500],
	},
	display: {
		fontSize: '4rem',
		fontWeight: 700,
		lineHeight: '5rem',
	},
	button: {
		fontSize: '1.4rem',
		fontWeight: 600,
		lineHeight: '2.4rem',
		letterSpacing: 1,
	},
	subtitle1: {
		fontSize: '1.4rem',
		fontWeight: 500,
		lineHeight: '2.4rem',
	},
	subtitle2: {
		fontSize: '1.4rem',
		fontWeight: 700,
		lineHeight: '2.4rem',
	},
	overline: {
		fontSize: '1.2rem',
		fontWeight: 700,
		lineHeight: '2.2rem',
	},
	caption: {
		fontSize: '1.2rem',
		fontWeight: 500,
		lineHeight: '2.2rem',
	},
};

export const breakpoints = {
	values: {
		xs: 0,
		sm: 320,
		md: 768,
		xm: 960,
		lg: 1024,
		xl: 1200,
	},
};

const styleAsterisk = {
	color: palette.brand.orange[500],
};

const styleLabel = {
	fontWeight: 500,
	color: brand.grey[800],
	cursor: 'pointer',
};

const inputBorderRadius = 6;

const colorHoverOnDark = 'rgba(255, 255, 255, 0.1)';

const variables = {
	headerHeight: '70px',
	navWidthCollapsed: '60px',
	navWidthExpanded: '200px',
};

export const components = {
	MuiAlert: {
		styleOverrides: {
			filledError: {
				backgroundColor: palette.error.main,
				color: '#ffffff',
			},
			filledInfo: {
				backgroundColor: palette.info.main,
				color: '#ffffff',
			},
			filledSuccess: {
				backgroundColor: palette.success.dark,
				color: '#ffffff',
			},
			filledWarning: {
				backgroundColor: palette.warning.dark,
				color: '#ffffff',
			},
			outlinedError: {
				backgroundColor: palette.error.light,
				borderColor: palette.error.dark,
				color: palette.error.dark,
			},
			outlinedInfo: {
				backgroundColor: palette.info.light,
				borderColor: palette.info.dark,
				color: palette.info.dark,
			},
			outlinedSuccess: {
				backgroundColor: palette.success.light,
				borderColor: palette.success.dark,
				color: palette.success.dark,
			},
			outlinedWarning: {
				backgroundColor: palette.warning.light,
				borderColor: palette.warning.dark,
				color: palette.warning.dark,
			},
			standardError: {
				backgroundColor: palette.error.light,
				color: palette.error.dark,
			},
			standardInfo: {
				backgroundColor: palette.info.light,
				color: palette.info.dark,
			},
			standardSuccess: {
				backgroundColor: palette.success.light,
				color: palette.success.dark,
			},
			standardWarning: {
				backgroundColor: palette.warning.light,
				color: palette.warning.dark,
			},
		},
	},
	MuiAvatar: {
		styleOverrides: {
			root: {
				width: 34,
				height: 34,
				'& .MuiSvgIcon-root': {
					fontSize: '2rem',
				},
				'&.small': {
					width: 28,
					height: 28,
					'& .MuiSvgIcon-root': {
						fontSize: '1.4rem',
					},
				},
				'&.large': {
					width: 44,
					height: 44,
					'& .MuiSvgIcon-root': {
						fontSize: '2.6rem',
					},
				},
				'&.xlarge': {
					width: 64,
					height: 64,
					'& .MuiSvgIcon-root': {
						fontSize: '3.8rem',
					},
				},
			},
			fallback: {
				width: '1em',
				height: '1em',
			},
		},
	},
	MuiBadge: {
		styleOverrides: {
			badge: {
				fontWeight: 600,
				fontSize: `1.2rem`,
				lineHeight: `1.2rem`,
			},
		},
	},
	MuiButton: {
		defaultProps: {
			disableElevation: true,
		},
		styleOverrides: {
			root: {
				borderRadius: inputBorderRadius,
				textTransform: 'none' as const,
				letterSpacing: 0,
			},
			contained: {
				'&.Mui-disabled': {
					backgroundColor: brand.grey[400],
					color: brand.grey[600],
				},
			},
			outlined: {
				'&.Mui-disabled': {
					borderColor: brand.grey[600],
					color: brand.grey[600],
				},
			},
			text: {
				padding: 0,
				minWidth: 0,
				'&:hover': {
					backgroundColor: 'transparent',
				},
			},
			sizeLarge: {
				fontSize: '1.4rem',
			},
		},
		variants: [
			{
				props: {
					color: 'white' as const,
					variant: 'text' as const,
				},
				style: {
					'&:hover': {
						backgroundColor: colorHoverOnDark,
					},
				},
			},
		],
	},
	MuiCheckbox: {
		styleOverrides: {
			root: {
				'.MuiSvgIcon-root': {
					fontSize: '2.4rem',
				},
			},
			sizeSmall: {
				'.MuiSvgIcon-root': {
					fontSize: '2rem',
				},
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			root: {
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
				gap: 8,
				padding: `0 16px`,
				fontWeight: 600,
				fontSize: '1.2rem',
				lineHeight: '2.2rem',
				'&.tag': {
					border: 'none',
					borderRadius: 4,
					padding: `0 8px`,
				},
				'&.circle': {
					borderRadius: '50%',
					padding: 5,
					height: 'auto',
					gap: 0,
				},
			},
			sizeSmall: {
				height: 26,
				gap: 4,
				padding: `0 10px`,
			},
			icon: {
				margin: 0,
				fontSize: '2rem',
				fill: 'currentColor',
			},
			iconSmall: {
				fontSize: '1.8rem',
			},
			label: {
				padding: 0,
			},
			outlinedError: {
				borderColor: palette.error.dark,
				color: palette.error.dark,
			},
			outlinedInfo: {
				borderColor: palette.info.dark,
				color: palette.info.dark,
			},
			outlinedSuccess: {
				borderColor: palette.success.dark,
				color: palette.success.dark,
			},
			outlinedWarning: {
				borderColor: palette.warning.dark,
				color: palette.warning.dark,
			},
			outlinedMagenta: {
				borderColor: palette.magenta.dark,
				color: palette.magenta.dark,
			},
			outlinedPurple: {
				borderColor: palette.purple.dark,
				color: palette.purple.dark,
			},
		},
		variants: [
			{
				props: {
					color: 'default' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.brand.grey[100],
					border: `1px ${palette.brand.grey[400]} solid`,
					color: '#000000',
				},
			},
			{
				props: {
					color: 'primary' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.primary.light,
					border: `1px ${palette.primary.main} solid`,
					color: palette.primary.main,
				},
			},
			{
				props: {
					color: 'secondary' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.secondary.light,
					border: `1px ${palette.secondary.main} solid`,
					color: palette.secondary.main,
				},
			},
			{
				props: {
					color: 'neutral' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.neutral.light,
					border: `1px ${palette.neutral.main} solid`,
					color: palette.neutral.main,
				},
			},
			{
				props: {
					color: 'error' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.error.light,
					border: `1px ${palette.error.dark} solid`,
					color: palette.error.dark,
				},
			},
			{
				props: {
					color: 'info' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.info.light,
					border: `1px ${palette.info.dark} solid`,
					color: palette.info.dark,
				},
			},
			{
				props: {
					color: 'success' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.success.light,
					border: `1px ${palette.success.dark} solid`,
					color: palette.success.dark,
				},
			},
			{
				props: {
					color: 'warning' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.warning.light,
					border: `1px ${palette.warning.dark} solid`,
					color: palette.warning.dark,
				},
			},
			{
				props: {
					color: 'magenta' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.magenta.light,
					border: `1px ${palette.magenta.dark} solid`,
					color: palette.magenta.dark,
				},
			},
			{
				props: {
					color: 'purple' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.purple.light,
					border: `1px ${palette.purple.dark} solid`,
					color: palette.purple.dark,
				},
			},
			{
				props: {
					color: 'teal' as const,
					variant: 'twoTone' as const,
				},
				style: {
					backgroundColor: palette.teal.light,
					border: `1px ${palette.teal.dark} solid`,
					color: palette.teal.dark,
				},
			},
		],
	},
	MuiCssBaseline: {
		styleOverrides: {
			html: {
				fontSize: '62.5%',
			},
		},
	},
	MuiFormControlLabel: {
		styleOverrides: {
			asterisk: styleAsterisk,
			label: styleLabel,
		},
	},
	MuiFormLabel: {
		styleOverrides: {
			root: styleLabel,
		},
	},
	MuiIconButton: {
		variants: [
			{
				props: {
					color: 'white' as const,
				},
				style: {
					color: '#ffffff',
					'&:hover': {
						backgroundColor: colorHoverOnDark,
					},
				},
			},
		],
	},
	MuiInputAdornment: {
		styleOverrides: {
			positionEnd: {
				margin: `0 0 0 4px !important`,
			},
			positionStart: {
				margin: `0 4px 0 0 !important`,
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				borderRadius: `${inputBorderRadius}px !important`,
				'&.MuiInputBase-adornedStart.MuiInputBase-sizeSmall': {
					paddingLeft: '8px !important',
				},
			},
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				...styleLabel,
				marginBottom: 4,
				color: 'inherit',
			},
			asterisk: styleAsterisk,
		},
	},
	MuiLinearProgress: {
		styleOverrides: {
			root: {
				borderRadius: 8,
				height: 8,
			},
		},
	},
	MuiLink: {
		styleOverrides: {
			root: {
				fontWeight: 600,
			},
		},
	},
	MuiListItemText: {
		styleOverrides: {
			primary: ({ theme }: StyleOverridesParams) => {
				return {
					color: theme.palette.brand.grey[800],
				};
			},
			secondary: ({ theme }: StyleOverridesParams) => {
				return {
					color: theme.palette.brand.grey[600],
				};
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: ({ theme }: StyleOverridesParams) => {
				return {
					backgroundColor: theme.palette.common.white,
					borderRadius: inputBorderRadius,
					':hover .MuiOutlinedInput-notchedOutline': {
						borderColor: brand.blue[700],
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: brand.blue[400],
						borderWidth: 1,
					},
				};
			},
			notchedOutline: {
				transition: 'all 200ms',
				borderColor: brand.grey[400],
			},
		},
	},
	MuiPaginationItem: {
		styleOverrides: {
			icon: {
				fontSize: '2rem',
			},
		},
	},
	MuiPaper: {
		styleOverrides: {
			root: {
				borderRadius: 8,
			},
			outlined: {
				borderColor: brand.grey[400],
			},
		},
	},
	MuiRadio: {
		styleOverrides: {
			root: {
				'.MuiSvgIcon-root': {
					fontSize: '2.4rem',
				},
			},
			sizeSmall: {
				'.MuiSvgIcon-root': {
					fontSize: '2rem',
				},
			},
		},
	},
	MuiSvgIcon: {
		styleOverrides: {
			root: {
				fontSize: '2.4rem',
			},
		},
	},
	MuiTab: {
		styleOverrides: {
			root: ({ theme }: StyleOverridesParams) => ({
				flexDirection: 'row' as const,
				gap: 8,
				padding: theme.spacing(0, 3, 1.5, 3),
				minHeight: 0,
				fontSize: `1.4rem`,
				lineHeight: `1.4rem`,
				letterSpacing: 0,
				color: palette.brand.grey[500],
				textTransform: 'none' as const,
				'& .MuiTab-iconWrapper': {
					margin: 0,
					width: 16,
					maxHeight: 16,
					fill: 'currentColor',
				},
			}),
		},
	},
	MuiTablePagination: {
		styleOverrides: {
			displayedRows: {
				margin: 0,
			},
			select: {
				fontSize: '1.4rem',
				lineHeight: '2.4rem',
			},
			selectLabel: {
				margin: 0,
			},
		},
	},
	MuiTabs: {
		styleOverrides: {
			root: {
				minHeight: 0,
			},
			indicator: ({ theme }: StyleOverridesParams) => {
				return {
					backgroundColor: brand.blue[500],
					height: `4px`,
				};
			},
		},
	},
	MuiTabPanel: {
		styleOverrides: {
			root: {
				padding: 0,
			},
		},
	},
	MuiTextField: {
		variants: [
			{
				props: {
					color: 'white' as const,
					variant: 'filled' as const,
				},
				style: {
					'& .MuiInputBase-root': {
						backgroundColor: '#ffffff !important',
					},
				},
			},
		],
	},
	MuiTooltip: {
		styleOverrides: {
			tooltip: {
				fontWeight: 700,
				fontSize: '1rem',
				lineHeight: '2rem',
			},
		},
	},
};

export const theme = createTheme({
	palette,
	typography,
	breakpoints,
	components,
	shadows,
	variables,
});
