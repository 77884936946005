// react
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// i18n
import './i18n';

// app
import { App } from './App';

// router
import { BrowserRouter } from 'react-router-dom';

// styles
import './global.css';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Suspense fallback={<></>}>
				<App />
			</Suspense>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
