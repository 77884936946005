// react
import { ChangeEvent, useContext, useEffect, useState } from 'react';

// types
import { DocumentType, ExpenseType, Transaction } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextUser } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid, Typography } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { Loading } from '../../ui/Loading/Loading';

// cards
import { CardUploadFile } from '../../cards/CardUploadFile/CardUploadFile';

// utils
import { formatCurrency } from '../../../utils/currency';
import { formatDate } from '../../../utils/dates';

// props
export interface DialogTransactionProps {
	isOpen: boolean;
	onClose: () => void;
	onUpload: (e: ChangeEvent<HTMLInputElement>, response: any) => void;
	transaction: Transaction | undefined;
}

export const DialogTransaction = ({
	isOpen,
	onClose,
	onUpload,
	transaction,
}: DialogTransactionProps) => {
	// context
	const { user } = useContext(ContextUser);

	// hooks
	const { t } = useTranslation();

	// session
	const token = sessionStorage.getItem('token');

	// state
	const [docTypes, setDocTypes] = useState<DocumentType[]>([]);

	// vars
	const expenseType = transaction?.expenseType as ExpenseType;

	// fetch document types
	const { fetchRequest: fetchDocumentTypes, isLoading: isLoadingDocs } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/api/GetBLDTDocumentTypes/${user?.role}/${expenseType?.id}`,
		options: {
			headers: {
				Authorization: `Bearer ${token}`,
				apikey: process.env.REACT_APP_APIKEY || '',
			},
		},
		onSuccess: (res) => {
			console.log('RES', res);
			if (res.data) {
				setDocTypes(res.data);
			}
		},
	});

	// handlers
	const handleUpload = async (file: File, docType: DocumentType) => {
		const formData = new FormData();

		if (user && file && transaction) {
			const folderName = user.role === 'SUPPLIER' ? 'supplier' : 'beneficiary';
			const path = `bldtext/${folderName}/${user.scope}/transactions/${transaction.id}/expense-type/${expenseType.id}/document-type/${docType.id}`;

			formData.append('file', file, file.name);
			formData.append('path', path);
			formData.append('transaction', String(transaction.id));
			formData.append('type', String(docType.id));
			formData.append('user', JSON.stringify(user));
		}

		try {
			const postUpload = await fetch(`${process.env.REACT_APP_API_URL}/api/Uploader`, {
				method: 'post',
				body: formData,
				headers: {
					Authorization: `Bearer ${token}`,
					apikey: process.env.REACT_APP_APIKEY || '',
				},
			});
			const resUpload = await postUpload.json();
			return resUpload;
		} catch (error) {
			return {
				error,
			};
		}
	};

	const handleClickCard = async (e: ChangeEvent<HTMLInputElement>, docType: DocumentType) => {
		// define files
		const { files } = e.target as HTMLInputElement;

		// upload file
		if (files) {
			const reqUpload = await handleUpload(files[0], docType);

			if (onUpload) {
				onUpload(e, reqUpload);
			}
		}
	};

	const renderContent = () => {
		if (isLoadingDocs) {
			return (
				<Grid item xs={12}>
					<Grid alignItems="center" container justifyContent="center">
						<Grid item>
							<Loading />
						</Grid>
					</Grid>
				</Grid>
			);
		} else if (docTypes.length) {
			return docTypes.map((docType, index) => {
				return (
					<Grid item key={`document-upload-${index}`} xs={12}>
						<CardUploadFile
							onUpload={(e) => handleClickCard(e, docType)}
							icon="upload"
							label={docType.name}
						/>
					</Grid>
				);
			});
		}

		return (
			<Grid item xs={12}>
				<Alert severity="info" variant="outlined">
					{t('alert.noDocumentsRequired')}
				</Alert>
			</Grid>
		);
	};

	// fetch document types when transaction changes
	useEffect(() => {
		if (transaction) {
			fetchDocumentTypes();
		}
	}, [fetchDocumentTypes, transaction]);

	if (!transaction) {
		return <></>;
	}

	return (
		<DialogActions hasClose={true} isOpen={isOpen} onClose={onClose} fullScreen={true}>
			<Grid alignItems="center" container spacing={2}>
				<Grid item xs={12}>
					<Grid alignItems="center" container direction="column" spacing={2}>
						<Grid item>
							<IconCircle type="usd" size="lg" />
						</Grid>
						<Grid item sx={{ textAlign: 'center' }}>
							<Typography variant="h2" fontWeight={600} color="common.white">
								{formatCurrency({ currency: transaction.currency, value: transaction.amount })}
							</Typography>
							<Typography color="brand.blue.400" fontWeight={600}>
								{formatDate(new Date(transaction.date))}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				{renderContent()}
			</Grid>
		</DialogActions>
	);
};
