// react
import { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { NavLink } from 'react-router-dom';

// hooks
import { useAuth } from '../../../hooks/useAuth';

// mui
import { ClassNameMap, IconButton, Typography } from '@mui/material';

// icons
import {
	ClearOutlined as IconClear,
	FolderOutlined as IconFolder,
	LogoutOutlined as IconLogout,
	PersonOutlined as IconPerson,
} from '@mui/icons-material';

// styles
import useStyles from './Nav.styles';

// props
export interface NavItem {
	className?: string;
	icon: ReactNode;
	label: string;
	onClick?: MouseEventHandler;
	to?: string;
}

export interface NavProps {
	isOpen?: boolean;
	onClose?: MouseEventHandler;
}

export const Nav = ({ isOpen, onClose }: NavProps) => {
	// hooks
	const { logout } = useAuth();
	const styles: ClassNameMap = useStyles();
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(false);

	// vars
	const classes = [styles.nav];
	const classesDrawer = [styles.drawer];
	const classesOverlay = [styles.overlay];

	const handleClickNavItem = (e: MouseEvent<HTMLButtonElement>) => {
		setIsExpanded(false);

		if (onClose) {
			onClose(e);
		}
	};

	const items = [
		{
			icon: <IconFolder />,
			label: t('dashboard'),
			to: '/dashboard',
		},
		{
			icon: <IconPerson />,
			label: t('profile'),
			to: '/profile',
		},
		{
			icon: <IconLogout />,
			label: t('logout'),
			onClick: () => {
				logout();
			},
		},
	];

	const renderItems = (items: NavItem[]) => {
		return items.map((item, i) => {
			const { className, icon, label, ...itemProps } = item;
			const classesBtnItem = [styles.btnItem];
			const classesBtnItemText = [styles.btnItemText];

			// eslint-disable-next-line
			let Component: any = 'button';

			if (className) {
				classesBtnItem.push(className);
			}

			if (isExpanded) {
				classesBtnItem.push(styles.btnItemExpanded);
				classesBtnItemText.push(styles.btnItemTextExpanded);
			}

			if (item.to) {
				Component = NavLink;
			}

			const btn = (
				<Component
					className={classesBtnItem.join(' ')}
					key={`nav-item-${i}`}
					onClick={(e: MouseEvent<HTMLButtonElement>) => handleClickNavItem(e)}
					{...itemProps}>
					{icon}
					<Typography className={classesBtnItemText.join(' ')} component="div" fontWeight={600}>
						{label}
					</Typography>
				</Component>
			);

			return btn;
		});
	};

	if (isOpen) {
		classesDrawer.push(styles.drawerOpen);
	}

	return (
		<div className={classes.join(' ')}>
			<div className={classesOverlay.join(' ')} onClick={() => setIsExpanded(false)} />
			<div className={classesDrawer.join(' ')}>
				<nav className={styles.list}>
					<div className={styles.sublist}>{renderItems(items)}</div>
				</nav>

				<div className={styles.btnClose}>
					<IconButton color="white" onClick={onClose}>
						<IconClear />
					</IconButton>
				</div>
			</div>
		</div>
	);
};
