// react
import { MouseEventHandler } from 'react';

// router
import { Link as RouterLink } from 'react-router-dom';

// mui
import { AppBar, Box, IconButton, Grid, Link, Toolbar } from '@mui/material';

// icons
import { MenuOutlined as IconMenu } from '@mui/icons-material';

// logos
import { LogoFundsChainAlt } from '../../img/LogoFundsChainAlt';

// props
export interface HeaderProps {
	onOpenNav?: MouseEventHandler;
}

// types
export type Anchor = HTMLButtonElement | null;

export const Header = ({ onOpenNav }: HeaderProps) => {
	return (
		<AppBar
			color="secondary"
			elevation={0}
			position="static"
			sx={(theme) => {
				return {
					flexDirection: 'row',
					alignItems: 'center',
					borderRadius: `0 !important`,
					height: theme.variables?.headerHeight,
				};
			}}>
			<Toolbar
				disableGutters={true}
				sx={(theme) => {
					return {
						justifyContent: 'space-between',
						padding: theme.spacing(0, 3),
						width: '100%',
						height: '100%',
						minHeight: `0 !important`,
					};
				}}>
				<Grid alignItems="center" container columnSpacing={2} justifyContent="space-between">
					<Grid item xs={4} md={3} lg={5}>
						<Link color="common.white" component={RouterLink} to="/dashboard" underline="none">
							<Box sx={{ width: '150px' }}>
								<LogoFundsChainAlt />
							</Box>
						</Link>
					</Grid>

					<Grid item xs={8} md={9} lg={7}>
						<Grid
							alignItems="center"
							container
							columnSpacing={{ xs: 1, md: 2 }}
							justifyContent="flex-end">
							<Grid item xs="auto">
								<Grid alignItems="center" container columnSpacing={1}>
									<Grid item xs="auto">
										<IconButton color="white" onClick={onOpenNav}>
											<IconMenu />
										</IconButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};
