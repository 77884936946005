// router
import { useNavigate } from 'react-router-dom';

export const useAuth = () => {
	// hooks
	const navigate = useNavigate();

	const logout = (redirectUrl: string = '/') => {
		const token = sessionStorage.getItem('token');
		fetch(`${process.env.REACT_APP_API_URL}/api/Logout`, {
				headers: {
					Authorization: `Bearer ${token}`,
					apikey: process.env.REACT_APP_APIKEY || '',
				},
			},
		).then((response) => {
			if (response.ok) {
				console.log('User logged out', response);
				// remove session storage
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('user');

				// navigate to login page
				navigate(redirectUrl);
			} else {
				console.error('User not logged out');
			}
		}).catch((error) => {
			console.error('User not logged out', error);
		});

	};

	return {
		logout,
	};
};
