import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	circle: {
		backgroundColor: theme.palette.brand.grey[200],
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.brand.grey[700],
	},
	/* sizes */
	sm: {
		width: 32,
		height: 32,
		'& svg': {
			fontSize: '1.8rem',
		},
	},
	md: {
		width: 42,
		height: 42,
		'& svg': {
			fontSize: '2.4rem',
		},
	},
	lg: {
		width: 64,
		height: 64,
		'& svg': {
			fontSize: '3.6rem',
		},
	},
	/* types */
	account: {
		backgroundColor: theme.palette.brand.blue[200],
		color: theme.palette.brand.blue[700],
	},
	check: {
		backgroundColor: theme.palette.brand.green[400],
		color: theme.palette.common.white,
	},
	contract: {
		backgroundColor: theme.palette.brand.yellow[100],
		color: theme.palette.brand.yellow[700],
	},
	download: {
		backgroundColor: theme.palette.brand.blue[500],
		color: theme.palette.common.white,
	},
	entity: {
		backgroundColor: theme.palette.brand.magenta[100],
		color: theme.palette.brand.magenta[700],
	},
	loan: {
		backgroundColor: theme.palette.brand.teal[100],
		color: theme.palette.brand.teal[700],
	},
	local: {
		backgroundColor: theme.palette.brand.blue[200],
		color: theme.palette.brand.blue[700],
	},
	project: {
		backgroundColor: theme.palette.brand.purple[100],
		color: theme.palette.brand.purple[700],
	},
	supplier: {
		backgroundColor: theme.palette.brand.magenta[100],
		color: theme.palette.brand.magenta[700],
	},
	transaction: {
		backgroundColor: theme.palette.brand.blue[200],
		color: theme.palette.brand.blue[700],
	},
	usd: {
		backgroundColor: theme.palette.brand.blue[200],
		color: theme.palette.brand.blue[700],
	},
	user: {
		backgroundColor: theme.palette.brand.orange[100],
		color: theme.palette.brand.orange[700],
	},
	upload: {
		backgroundColor: theme.palette.brand.blue[500],
		color: theme.palette.common.white,
	},
}));

export default useStyles;
