// react
import { useContext, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { TraceabilityTransactions } from '../../../@types';

// context
import { ContextUser } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// layouts
import { Authenticated } from '../../layouts/Authenticated/Authenticated';

// mui
import { Container, Grid, Typography } from '@mui/material';

// pages
import { Documents } from './Documents/Documents';

// cards
import { CardProfile } from '../../cards/CardProfile/CardProfile';
import { CardTraceability } from '../../cards/CardTraceability/CardTraceability';

export const PageProfile = () => {
	// session
	const token = sessionStorage.getItem('token');

	// context
	const { user } = useContext(ContextUser);

	// hooks
	const { t } = useTranslation();

	// state
	const [traceability, setTraceability] = useState<TraceabilityTransactions>({
		transactions: {
			documented: 0,
			total: 0,
			undocumented: 0,
		},
		value: 0,
	});

	// fetch transactions
	useFetch({
		isEnabled: Boolean(user),
		url: `${process.env.REACT_APP_API_URL}/api/GetBLDTTI/${user?.role}/${user?.scope}`,
		options: {
			headers: {
				Authorization: `Bearer ${token}`,
				apikey: process.env.REACT_APP_APIKEY || '',
			},
		},
		onSuccess: (res) => {
			setTraceability(res);
		},
	});

	return (
		<Authenticated>
			<Container maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography color="common.white" variant="h2">
							{t('profile')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<CardProfile />
					</Grid>
					<Grid item xs={12}>
						<CardTraceability traceability={traceability} />
					</Grid>
					{user?.role === 'BENEFICIARY' && (
						<Grid item xs={12}>
							<Documents />
						</Grid>
					)}
				</Grid>
			</Container>
		</Authenticated>
	);
};
