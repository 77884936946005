// react
import { MouseEvent } from 'react';

export const downloadFile = (
	e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>,
	path: string,
	mimeType: string,
	name: string | undefined
) => {
	e.preventDefault();

	// session
	const token = sessionStorage.getItem('token');

	// remove first folder and trailing slash from path
	const filePath = path.replace('/bldtfileupload/', '');

	fetch(`${process.env.REACT_APP_API_URL}/api/Downloader/bldtfileupload`, {
		method: 'GET',
		headers: {
			'X-Blob': filePath,
			'Content-Type': mimeType,
			Authorization: `Bearer ${token}`,
			apikey: process.env.REACT_APP_APIKEY || '',
		},
	})
		.then((response) => {
			return response.blob();
		})
		.then((blob) => {
			// create link for blob
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');

			link.href = url;

			if (typeof name === 'string') {
				link.setAttribute('download', name);
			}

			document.body.appendChild(link);

			link.click();
		})
		.catch((error) => {
			console.error('DOWNLOAD ERROR', error);
		});
};

export const getFileNameFromPath = (p: string): string => {
	const r = p.split('/').pop();
	return r === undefined ? '' : r;
};

export const getPathFromUrl = (url: string) => {
	const el = document.createElement('a');
	el.href = url;
	return el.pathname;
};
