// react
import { useState } from 'react';

// types
import { Contract, Document, ExpenseType, UserRole } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Alert, Button, Box, Divider, Grid, Link, Paper, Typography, Chip } from '@mui/material';

// icons
import {
	ExpandLessOutlined as IconExpandLess,
	ExpandMoreOutlined as IconExpandMore,
} from '@mui/icons-material';

// types
import { Transaction } from '../../../@types';

// ui
import { ChipTraceability } from '../../ui/ChipTraceability/ChipTraceability';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { MetadataField } from '../../ui/MetadataField/MetadataField';

// utils
import { formatCurrency } from '../../../utils/currency';
import { formatDate } from '../../../utils/dates';
import { downloadFile, getPathFromUrl } from '../../../utils/documents';

// icons
import {
	CalendarTodayOutlined as IconCalendarToday,
	PersonOutlined as IconPerson,
} from '@mui/icons-material';

// props
export type CardTransactionProps = {
	documents?: Document[];
	onClick: (arg0: any) => void;
	role: UserRole;
	transaction: Transaction;
};

export const CardTransaction = ({
	documents = [],
	onClick,
	role,
	transaction,
}: CardTransactionProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(false);

	// vars
	const isSupplier = role === 'SUPPLIER';

	// handlers
	const handleClickToggle = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<Paper>
			<Grid container>
				<Grid item xs={12}>
					<Box
						sx={(theme) => {
							return {
								padding: theme.spacing(2),
							};
						}}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Grid container justifyContent="space-between" spacing={2}>
									<Grid item>
										<Grid alignItems="center" container spacing={1}>
											{transaction.expenseType && (
												<Grid item xs={12}>
													<Chip
														label={(transaction.expenseType as ExpenseType).name}
														size="small"
														variant="outlined"
													/>
												</Grid>
											)}
											<Grid item xs={12}>
												<Typography variant="h2" fontWeight={600} align="left">
													{formatCurrency({
														currency: transaction.currency,
														value: transaction.amount,
													})}
												</Typography>
												<Typography color="brand.grey.500" fontWeight={600} variant="caption">
													{formatDate(new Date(transaction.date))}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs="auto">
										<Button
											aria-label={t('upload')}
											color="primary"
											fullWidth={true}
											onClick={onClick}>
											<IconCircle type="upload" />
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid alignItems="flex-end" container justifyContent="space-between" spacing={2}>
									{isSupplier && (
										<Grid item>
											<Grid alignItems="center" container spacing={1}>
												<Grid item>
													<IconCircle size="sm" type="contract" />
												</Grid>
												<Grid item>
													<Typography variant="caption">
														{transaction.contract
															? (transaction.contract as Contract).num
															: t('noContract')}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									<Grid item xs={12}>
										<Grid alignItems="center" container justifyContent="space-between" spacing={2}>
											<Grid item>
												<Button onClick={handleClickToggle} variant="text">
													{isExpanded ? t('hideDocuments') : t('seeDocuments')}{' '}
													{isExpanded ? <IconExpandLess /> : <IconExpandMore />}
												</Button>
											</Grid>
											<Grid item>
												<ChipTraceability traceability={transaction.traceability} />
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>

			{isExpanded &&
				(documents.length ? (
					documents?.map((document, index) => {
						const path = getPathFromUrl(document.url);

						return (
							<Grid item key={`document=${index}`} xs={12}>
								<Divider />
								<Box
									sx={(theme) => {
										return {
											padding: theme.spacing(2),
										};
									}}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Link
												component="button"
												onClick={(e) =>
													downloadFile(e, path, document.metadata.type, document.name)
												}>
												{document.name}
											</Link>
											{document.type && (
												<Typography fontWeight={600}>{document.type.name}</Typography>
											)}
										</Grid>
										<Grid item xs={12}>
											<Grid container spacing={2}>
												<Grid item>
													<MetadataField
														icon={<IconCalendarToday />}
														label={formatDate(new Date(document.created))}
													/>
												</Grid>

												{document.user && (
													<Grid item>
														<MetadataField icon={<IconPerson />} label={document.user.fullName} />
													</Grid>
												)}
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						);
					})
				) : (
					<Grid item xs={12}>
						<Box
							sx={(theme) => {
								return {
									padding: theme.spacing(0, 2, 2, 2),
								};
							}}>
							<Alert severity="info">{t('noDocuments')}</Alert>
						</Box>
					</Grid>
				))}
		</Paper>
	);
};
