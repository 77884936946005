// react
import { useState } from 'react';

// types
import { LoginType } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// mui
import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';

// layouts
import { Gateway } from '../../layouts/Gateway/Gateway';

// forms
import { FormLogin } from '../../forms/FormLogin/FormLogin';

// props
export interface PageLoginProps {
	loginType?: LoginType;
}

export const PageLogin = ({ loginType }: PageLoginProps) => {
	// hooks
	const { t } = useTranslation();

	// params
	const [searchParams] = useSearchParams();
	const paramError = searchParams.get('error');

	// defaults
	let defaultError = null;

	if (paramError) {
		if (paramError === 'expired') {
			defaultError = t('error.sessionExpired');
		}
	}

	// state
	const [error] = useState<string | null>(defaultError);

	return (
		<Gateway>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography component="h3" variant="h4">
						{t('login')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					{loginType ? (
						<FormLogin loginType={loginType} />
					) : (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Button component={RouterLink} fullWidth={true} to="/login/sms" variant="outlined">
									{t('loginSms')}
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Box sx={{ position: 'relative' }}>
									<Divider />
									<Box
										sx={(theme) => {
											return {
												backgroundColor: theme.palette.common.white,
												position: 'absolute',
												top: '50%',
												left: '50%',
												padding: theme.spacing(0, 1),
												transform: 'translate(-50%, -50%)',
											};
										}}>
										<Typography color="brand.grey.500" variant="caption">
											{t('or')}
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Button component={RouterLink} fullWidth to="/login/email" variant="contained">
									{t('loginEmail')}
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
				<Grid item xs={12}>
					<Typography color="brand.grey.500" variant="caption">
						{loginType === 'sms' ? t('login.tos.sms') : t('login.tos.email')}
					</Typography>
				</Grid>
			</Grid>
		</Gateway>
	);
};
