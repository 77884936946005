// hooks
import { useTranslation } from 'react-i18next';

// mui
import { Grid, Typography } from '@mui/material';

// layouts
import { Gateway } from '../../layouts/Gateway/Gateway';

// props
export interface PageErrorProps {
	title?: string;
}

export const PageError = ({ title }: PageErrorProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<Gateway>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography component="h1" variant="h2">
						{title || t('notFound')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography>{t('error.pageNotFound')}</Typography>
				</Grid>
			</Grid>
		</Gateway>
	);
};
