// react
import { ChangeEvent, FormEvent, useContext, useState } from 'react';

// types
import { ErrorCause, LoginType, User } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useNavigate } from 'react-router-dom';

// jwt
import { jwtDecode } from 'jwt-decode';

// context
import { ContextUser } from '../../../App.context';

// mui
import { Alert, Button, Grid, InputLabel, TextField } from '@mui/material';

// props
export interface ReqJwtBody {
	email?: string;
	otp: number;
	sms?: number;
}

export interface ReqOtpBody {
	email?: string;
	sms?: number;
}

export interface FormLoginProps {
	loginType: LoginType;
}

export const FormLogin = ({ loginType }: FormLoginProps) => {
	// hooks
	const navigate = useNavigate();
	const { t } = useTranslation();

	// state
	const [email, setEmail] = useState('');
	const [error, setError] = useState<string | null>(null);
	const [hasOtp, setHasOtp] = useState(false);
	const [otp, setOtp] = useState('');
	const [phone, setPhone] = useState('');

	// context
	const { setUser } = useContext(ContextUser);

	// vars
	const idFormLogin = `form-login`;
	const idFormOtp = `form-otp`;

	const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
		setPhone(e.target.value);
	};

	const handleChangeOtp = (e: ChangeEvent<HTMLInputElement>) => {
		setOtp(e.target.value);
	};

	const handleSubmitLogin = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// clear error
		setError(null);

		let body: ReqJwtBody = {
			otp: Number(otp),
		};

		if (email) {
			body.email = email;
		}

		if (phone) {
			body.sms = Number(phone);
		}

		try {
			const response = await fetch(`${process.env['REACT_APP_API_URL']}/api/CreateJWT`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					apikey: process.env.REACT_APP_APIKEY || '',
				},
				body: JSON.stringify(body),
			});

			const { status, message } = await response.json();

			if (!status) {
				throw new Error('The OTP is invalid.', {
					cause: {
						id: 'LOGIN_INVALID_OTP',
					},
				});
			}

			const token = message.replace('Bearer ', '');
			const user = jwtDecode(message) as User;

			// session
			sessionStorage.setItem('token', token);
			sessionStorage.setItem('user', JSON.stringify(user));

			// set user
			setUser(user);

			// navigate to dashboard
			navigate('/dashboard');
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause.id === 'LOGIN_INVALID_OTP') {
				message = t('error.otpInvalid');
			}

			// set error
			setError(message);
		}
	};

	const handleSubmitOtp = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// clear error
		setError(null);

		let body: ReqOtpBody = {};

		if (email) {
			body.email = email;
		}

		if (phone) {
			body.sms = Number(phone);
		}

		try {
			const response = await fetch(`${process.env['REACT_APP_API_URL']}/api/CreateOTP`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					apikey: process.env.REACT_APP_APIKEY || '',
				},
				body: JSON.stringify(body),
			});
			const { status, message } = await response.json();

			if (!status) {
				throw new Error('The user could not be found.', {
					cause: {
						id: 'LOGIN_USER_NOT_FOUND',
					},
				});
			}

			console.log(`STATUS:`, status);
			console.log(`MESSAGE:`, message);

			setHasOtp(true);
		} catch (error) {
			let message = t('error.default');

			// set error
			setError(message);
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				{hasOtp ? (
					<form id={idFormLogin} onSubmit={handleSubmitLogin}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<InputLabel htmlFor={`${idFormLogin}-otp`} required={true}>
									{t('otp')}
								</InputLabel>
								<TextField
									fullWidth
									id={`${idFormLogin}-otp`}
									name="otp"
									onChange={handleChangeOtp}
									required={true}
									size="small"
									type="number"
									value={otp}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button fullWidth={true} type="submit" variant="contained">
									{t('signIn')}
								</Button>
							</Grid>
						</Grid>
					</form>
				) : (
					<form id={idFormOtp} onSubmit={handleSubmitOtp}>
						<Grid container spacing={2}>
							{loginType === 'email' && (
								<Grid item xs={12}>
									<InputLabel htmlFor={`${idFormOtp}-email`} required={true}>
										{t('email')}
									</InputLabel>
									<TextField
										fullWidth
										id={`${idFormOtp}-email`}
										name="email"
										placeholder="user@domain.com"
										onChange={handleChangeEmail}
										required={true}
										size="small"
										type="email"
										value={email}
									/>
								</Grid>
							)}
							{loginType === 'sms' && (
								<Grid item xs={12}>
									<InputLabel htmlFor={`${idFormOtp}-phone`} required={true}>
										{t('phoneNumber')}
									</InputLabel>
									<TextField
										fullWidth
										helperText="Include the country code"
										id={`${idFormOtp}-phone`}
										name="phone"
										required={true}
										onChange={handleChangePhone}
										placeholder="X XXX XXX XXXX"
										size="small"
										type="number"
										value={phone}
									/>
								</Grid>
							)}
							<Grid item xs={12}>
								<Button fullWidth color="primary" type="submit" variant="contained">
									{t('continue')}
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			</Grid>
			{error && (
				<Grid item xs={12}>
					<Alert severity="error">{error}</Alert>
				</Grid>
			)}
		</Grid>
	);
};
