// i18n
import { useTranslation } from 'react-i18next';

// types
import { TraceabilityTransactions } from '../../../@types';

// mui
import { Grid, Paper, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts';
import { styled } from '@mui/material/styles';

// theme
import { theme } from '../../../theme';

const StyledText = styled('text')(({ theme }) => ({
	textAnchor: 'middle',
	dominantBaseline: 'central',
	fontSize: 18,
	fontWeight: 600,
}));

// props
interface CardTraceabilityProps {
	traceability: TraceabilityTransactions;
}

export const CardTraceability = ({ traceability }: CardTraceabilityProps) => {
	// hooks
	const { t } = useTranslation();

	const pieParams = { innerRadius: 50, height: 100, margin: { right: 5 } };

	// define default colors
	const colors = {
		background: theme.palette.brand.grey[100],
		border: theme.palette.brand.grey[300],
		color: theme.palette.brand.grey[700],
	};

	if (traceability.value >= 1 && traceability.value <= 49) {
		colors.background = theme.palette.brand.orange[100];
		colors.border = theme.palette.brand.orange[300];
		colors.color = theme.palette.brand.orange[700];
	} else if (traceability.value >= 50 && traceability.value <= 70) {
		colors.background = theme.palette.brand.yellow[100];
		colors.border = theme.palette.brand.yellow[300];
		colors.color = theme.palette.brand.yellow[700];
	} else if (traceability.value >= 71) {
		colors.background = theme.palette.brand.green[100];
		colors.border = theme.palette.brand.green[300];
		colors.color = theme.palette.brand.green[700];
	}

	const PieCenterLabel = ({ children }: { children: React.ReactNode }) => {
		const { width, height, left, top } = useDrawingArea();
		return (
			<StyledText fill={colors.color} x={left + width / 2} y={top + height / 2}>
				{children}
			</StyledText>
		);
	};

	// vars
	return (
		<Paper
			elevation={0}
			sx={(theme) => {
				return {
					color: colors.color,
					backgroundColor: colors.background,
					border: `2px transparent solid`,
					borderColor: colors.border,
					height: '100%',
					padding: theme.spacing(3, 3, 3, 0),
				};
			}}>
			<Grid alignItems="center" container>
				<Grid item xs={5}>
					<PieChart
						series={[
							{
								data: [
									{ value: 100 - traceability.value, color: colors.border },
									{ value: traceability.value, color: colors.color },
								],
								innerRadius: 40,
								outerRadius: 50,
							},
						]}
						{...pieParams}>
						<PieCenterLabel>{`${traceability.value}%`}</PieCenterLabel>
					</PieChart>
				</Grid>
				<Grid item xs={true}>
					<Typography variant="h2">{t('traceability')}</Typography>
					<Typography>
						{t('transactionsDocumented', {
							documented: traceability.transactions.documented,
							total: traceability.transactions.total,
						})}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};
