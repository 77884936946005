import { ReactNode } from 'react';

// mui
import { ClassNameMap } from '@mui/material';

// icons
import {
	AccountBalanceOutlined as IconAccountBalance,
	AssignmentOutlined as IconAssignment,
	DownloadOutlined as IconDownload,
	AttachMoneyOutlined as IconUSD,
	CloudUploadOutlined as IconUpload,
	CheckOutlined as IconCheck,
	DeleteOutlined as IconDelete,
	FlagOutlined as IconFlag,
	FolderOutlined as IconFolder,
	InfoOutlined as IconInfo,
	PaymentsOutlined as IconPayments,
	PersonOutlined as IconPerson,
	SaveOutlined as IconSave,
	SearchOutlined as IconSearch,
	StoreOutlined as IconStore,
	SwapHorizOutlined as IconTransfer,
} from '@mui/icons-material';

// img
import { LogoWorldBank } from '../../img/LogoWorldBank';

// styles
import useStyles from './IconCircle.styles';

// props
export const iconCircleSizes = ['sm', 'md', 'lg'];
export const iconCircleTypes = [
	'account',
	'check',
	'contract',
	'default',
	'download',
	'entity',
	'expenditure',
	'loan',
	'local',
	'project',
	'search',
	'supplier',
	'transfer',
	'user',
];

export type IconCircleSize = (typeof iconCircleSizes)[number];
export type IconCircleType = (typeof iconCircleTypes)[number];

export interface IconCircleProps {
	icon?: ReactNode;
	size?: IconCircleSize;
	type?: IconCircleType;
}

// vars
const icons: Record<IconCircleType, ReactNode> = {
	account: (
		<LogoWorldBank
			hasGradient={false}
			hasLogoType={false}
			hasSubtitle={false}
			style={{ width: 20 }}
		/>
	),
	check: <IconCheck />,
	contract: <IconAssignment />,
	default: <IconInfo />,
	delete: <IconDelete />,
	download: <IconDownload />,
	entity: <IconAccountBalance />,
	expenditure: <IconSave />,
	loan: <IconPayments />,
	local: <IconFlag />,
	project: <IconFolder />,
	search: <IconSearch />,
	supplier: <IconStore />,
	transfer: <IconTransfer />,
	usd: <IconUSD />,
	user: <IconPerson />,
	upload: <IconUpload />,
};

export const IconCircle = ({ icon, size = 'md', type = 'default' }: IconCircleProps) => {
	// hooks
	const styles: ClassNameMap = useStyles();

	// vars
	const classes = [styles.circle, styles[size]];
	const transactionTypes = ['expenditure', 'transfer'];

	if (type !== 'default') {
		classes.push(styles[type]);
	}
	if (transactionTypes.includes(type)) {
		classes.push(styles.transaction);
	}

	return <div className={classes.join(' ')}>{icon || icons[type]}</div>;
};
