// react
import { useContext } from 'react';

// context
import { ContextUser } from '../../../App.context';

// pages
import { PageBeneficiary } from './Beneficiary/Beneficiary';
import { PageSupplier } from './Supplier/Supplier';

export const PageDashboard = () => {
	// context
	const { user } = useContext(ContextUser);

	if (user?.role === 'BENEFICIARY') {
		return <PageBeneficiary />;
	} else {
		return <PageSupplier />;
	}
};
