import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	btnClose: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
	},
	btnItem: {
		transition: 'all 200ms',
		borderRadius: 8,
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
		padding: theme.spacing(1),
		width: '100%',
		textDecoration: 'none',
		color: theme.palette.common.white,
		'& svg': {
			fill: theme.palette.brand.blue[400],
		},
		'&:hover, &.active': {
			backgroundColor: theme.palette.brand.blue[600],
		},
	},
	btnItemExpanded: {
		justifyContent: 'flex-start',
	},
	btnItemTextExpanded: {
		display: 'inline-flex',
	},
	btnItemToggle: {
		display: 'none',
	},
	drawer: {
		transition: 'all 200ms',
		transform: 'translateX(-100%)',
		backgroundColor: theme.palette.secondary.main,
		position: 'fixed',
		top: 0,
		left: 0,
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: `100vh`,
		overflowY: 'auto',
		padding: theme.spacing(12, 2, 2, 2),
		[`${theme.breakpoints.up('md')}`]: {
			padding: theme.spacing(12, 4, 4, 4),
		},
	},
	drawerOpen: {
		transform: 'translateX(0)',
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flex: 1,
		gap: theme.spacing(1),
		width: '100%',
	},
	nav: {
		position: 'relative',
	},
	overlay: {
		transition: 'all 200ms',
		backgroundColor: 'white',
		backdropFilter: 'blur(3px)',
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		opacity: 0,
		pointerEvents: 'none',
	},
	overlayExpanded: {
		opacity: 1,
		pointerEvents: 'all',
	},
	sublist: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
	},
}));

export default useStyles;
