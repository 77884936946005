// layouts
import { Authenticated } from '../../../layouts/Authenticated/Authenticated';

// ui
import { Transactions } from '../../../ui/Transactions/Transactions';

// mui
import { Grid } from '@mui/material';

export const PageSupplier = () => {
	return (
		<Authenticated>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Transactions role="SUPPLIER" />
				</Grid>
			</Grid>
		</Authenticated>
	);
};
