// react
import { useState } from 'react';

// router
import { Route, Routes } from 'react-router-dom';

// pages
import { PageDashboard } from './components/pages/Dashboard/Dashboard';
import { PageError } from './components/pages/Error/Error';
import { PageLogin } from './components/pages/Login/Login';
import { PageProfile } from './components/pages/Profile/Profile';

// types
import { Document, User } from './@types';

// hooks
import { useFetch } from './hooks/useFetch';

// context
import { ContextBanner, ContextDocsUser, ContextUser } from './App.context';

// styles
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// ui
import { BannerProps } from './components/ui/Banner/Banner';

// theme
import { theme } from './theme';

// blk
import BlockView from './blk/BlockView';
import TxView from './blk/TxView';

export const App = () => {
	// session
	const token = sessionStorage.getItem('token');
	const sessionUser = sessionStorage.getItem('user');
	const userDefault = sessionUser ? JSON.parse(sessionUser) : null;

	// state
	const [banner, setBanner] = useState<BannerProps | null>(null);
	const [docsUser, setDocsUser] = useState<Document[]>([]);
	const [user, setUser] = useState<User | null>(userDefault);

	console.log('ENV', process.env);

	const { refetch: refetchDocsUser } = useFetch({
		isEnabled: Boolean(token && user?.scope),
		url: `${process.env.REACT_APP_API_URL}/api/GetBlobs/BENEFICIARY/${user?.scope}?category=IDENTIFICATION,LICENSE`,
		options: {
			headers: {
				Authorization: `Bearer ${token}`,
				apikey: process.env.REACT_APP_APIKEY || '',
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setDocsUser(res.data);
			}
		},
	});

	return (
		<ContextUser.Provider value={{ user, setUser }}>
			<ContextBanner.Provider value={{ banner, setBanner }}>
				<ContextDocsUser.Provider value={{ docsUser, refetchDocsUser, setDocsUser }}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<Routes>
							{/* login */}
							<Route path="/" element={<PageLogin />} />
							<Route path="/login/email" element={<PageLogin loginType="email" />} />
							<Route path="/login/sms" element={<PageLogin loginType="sms" />} />

							{/* blk */}
							<Route path="/blk" element={<BlockView />} />
							<Route path="/view/:id" element={<TxView />} />

							{/* pages */}
							<Route path="/dashboard" element={<PageDashboard />} />
							<Route path="/profile" element={<PageProfile />} />
							<Route path="/error" element={<PageError />} />

							{/* wildcard */}
							<Route path="*" element={<PageError />} />
						</Routes>
					</ThemeProvider>
				</ContextDocsUser.Provider>
			</ContextBanner.Provider>
		</ContextUser.Provider>
	);
};
