// react
import { createContext, Dispatch, SetStateAction } from 'react';

// types
import { Document, User } from './@types';

// ui
import { BannerProps } from './components/ui/Banner/Banner';

export interface ContextBannerProps {
	banner: BannerProps | null;
	setBanner: Dispatch<SetStateAction<BannerProps | null>>;
}

export const ContextBanner = createContext<ContextBannerProps>({
	banner: null,
	setBanner: () => {
		console.log('SET BANNER');
	},
});

export interface ContextUserProps {
	user: User | null;
	setUser: Dispatch<SetStateAction<User | null>>;
}

export const ContextUser = createContext<ContextUserProps>({
	user: null,
	setUser: () => {
		console.log('SET USER');
	},
});

export interface ContextDocsUserProps {
	docsUser: Document[];
	refetchDocsUser: () => void;
	setDocsUser: Dispatch<SetStateAction<Document[]>>;
}

export const ContextDocsUser = createContext<ContextDocsUserProps>({
	docsUser: [],
	refetchDocsUser: () => {
		console.log('REFETCH USER DOCS');
	},
	setDocsUser: () => {
		console.log('SET USER DOCS');
	},
});
