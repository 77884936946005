// react
import { ChangeEvent } from 'react';

// mui
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// theme
import { theme } from '../../../theme';

// props
export type CardUploadFileProps = {
	icon: string;
	label?: string;
	onUpload: (e: ChangeEvent<HTMLInputElement>) => void;
	docType?: string;
};

export const CardUploadFile = ({ icon, label, onUpload }: CardUploadFileProps) => {
	return (
		<Paper>
			<Button
				color="primary"
				fullWidth={true}
				component={'label'}
				sx={{
					height: '100%',
					padding: theme.spacing(2),
				}}>
				<TextField
					type="file"
					onChange={onUpload}
					sx={(theme) => {
						return {
							clip: 'rect(0 0 0 0)',
							clipPath: 'inset(50%)',
							height: 1,
							overflow: 'hidden',
							position: 'absolute',
							bottom: 0,
							left: 0,
							whiteSpace: 'nowrap',
							width: 1,
						};
					}}
				/>

				<Grid
					alignItems="center"
					container
					columnSpacing={1}
					justifyContent="space-between"
					height="100%">
					<Grid item xs={true}>
						<Typography fontWeight={600} align="left">
							{label}
						</Typography>
					</Grid>
					<Grid item xs="auto">
						<IconCircle type={icon} />
					</Grid>
				</Grid>
			</Button>
		</Paper>
	);
};
