// insights
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
	config: {
		connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
		enableAutoRouteTracking: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
		enableAjaxPerfTracking: true,
		distributedTracingMode: 2, // Enable W3C distributed tracing
	},
});

appInsights.loadAppInsights();
appInsights.trackPageView();

console.log('Application Insights initialized successfully');

const trackEvent = (eventName: any, properties = {}) => {
	if (appInsights) {
		appInsights.trackEvent({ name: eventName, properties });
	} else {
		console.log('Telemetry disabled. Would have tracked:', eventName, properties);
	}
};

export { appInsights, trackEvent };
