// react
import { ReactNode } from 'react';

// mui
import { Box, Grid, Typography } from '@mui/material';

// props
export interface MetadataFieldProps {
	icon: ReactNode;
	label: ReactNode;
}

export const MetadataField = ({ icon, label }: MetadataFieldProps) => {
	return (
		<Grid alignItems="center" container spacing={1}>
			<Grid item>
				<Box
					sx={(theme) => {
						return {
							'& svg': {
								color: theme.palette.brand.blue[400],
								width: 16,
							},
						};
					}}>
					{icon}
				</Box>
			</Grid>
			<Grid item>
				<Typography component="time" color="brand.grey.500" variant="caption">
					{label}
				</Typography>
			</Grid>
		</Grid>
	);
};
