// react
import { MouseEvent, ReactNode } from 'react';

// types
import { ActionButton } from '../../../@types';

// mui
import { Button, Dialog, IconButton, Grid, Typography, TypographyProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ui
import { IconCircle, IconCircleProps } from '../../ui/IconCircle/IconCircle';

// icons
import { ClearOutlined as IconClear } from '@mui/icons-material';

// props
export interface DialogActionsProps {
	actions?: ActionButton[];
	alignItems?: CSSStyleDeclaration['alignItems'];
	children?: ReactNode;
	content?: ReactNode;
	hasClose?: boolean;
	isOpen: boolean;
	maxWidth?: number;
	onClose: (e: MouseEvent<HTMLButtonElement>) => void;
	title?: TypographyProps;
	type?: IconCircleProps['type'];
	fullScreen?: boolean;
}

export const DialogActions = ({
	actions = [],
	alignItems = 'flex-start',
	children,
	content,
	hasClose = false,
	isOpen = false,
	maxWidth = 500,
	onClose,
	title,
	type,
	fullScreen,
}: DialogActionsProps) => {
	const renderTitle = () => {
		if (!title) {
			return false;
		}

		const { children, fontWeight, variant, ...titleProps } = title;

		return (
			<Typography
				fontWeight={fontWeight || 600}
				id="dialog-actions-title"
				variant={variant || 'h2'}
				{...titleProps}>
				{children}
			</Typography>
		);
	};

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="dialog-actions-title"
			aria-describedby="dialog-actions-description"
			maxWidth="md"
			fullScreen={fullScreen}
			PaperProps={{
				sx: (theme) => {
					return {
						padding: {
							xs: theme.spacing(2),
							md: theme.spacing(4),
						},
						maxWidth: fullScreen ? null : maxWidth,
						backgroundColor: theme.palette.brand.blue[800],
					};
				},
			}}>
			<Grid container spacing={2}>
				{(type || title) && (
					<Grid item xs={12}>
						<Grid container direction="column" alignItems={alignItems} spacing={1}>
							{type && (
								<Grid item>
									<IconCircle size="lg" type={type} />
								</Grid>
							)}
							{title && <Grid item>{renderTitle()}</Grid>}
						</Grid>
					</Grid>
				)}

				<Grid id="dialog-actions-description" item xs={12}>
					{children}
					{typeof content === 'string' ? <Typography>{content}</Typography> : content}
				</Grid>

				{actions.length > 0 && (
					<Grid item xs={12}>
						<Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
							{actions.map((action, i) => {
								const { children, ...propsAction } = action;
								const Component = 'loading' in action ? LoadingButton : Button;
								return (
									<Grid item key={`dialog-action-btn-${i}`}>
										<Component {...propsAction}>{children}</Component>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				)}
			</Grid>
			{hasClose && (
				<IconButton
					aria-label="Close"
					color="white"
					onClick={onClose}
					size="small"
					sx={(theme) => {
						return {
							position: 'absolute',
							right: theme.spacing(1),
							top: theme.spacing(1),
						};
					}}>
					<IconClear />
				</IconButton>
			)}
		</Dialog>
	);
};
