// react
import { ChangeEvent, useContext } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Document } from '../../../../@types';

// mui
import { Grid, Typography } from '@mui/material';

// context
import { ContextBanner, ContextDocsUser, ContextUser } from '../../../../App.context';

// cards
import { CardUploadFile } from '../../../cards/CardUploadFile/CardUploadFile';

export const Documents = () => {
	// session
	const token = sessionStorage.getItem('token');

	// hooks
	const { t } = useTranslation();

	// context
	const { docsUser, refetchDocsUser } = useContext(ContextDocsUser);
	const { setBanner } = useContext(ContextBanner);
	const { user } = useContext(ContextUser);

	// handlers
	interface HandleUploadParams {
		category: string;
		file: File;
		onError?: (error: unknown) => void;
		onSuccess?: (response: any) => void;
	}

	const handleUpload = async ({ category, file, onError, onSuccess }: HandleUploadParams) => {
		const formData = new FormData();

		if (file && user) {
			formData.append('category', category);
			formData.append('file', file, file.name);
			formData.append('path', `bldtext/beneficiary/${user.scope}/identity`);
			formData.append('user', JSON.stringify(user));
		}

		try {
			const postUpload = await fetch(`${process.env.REACT_APP_API_URL}/api/Uploader`, {
				method: 'post',
				body: formData,
				headers: {
					Authorization: `Bearer ${token}`,
					apikey: process.env.REACT_APP_APIKEY || '',
				},
			});
			const resUpload = await postUpload.json();
			console.log('Upload Success', resUpload.data);

			if (onSuccess) {
				onSuccess(resUpload);
			}
		} catch (error) {
			console.error('Upload Error', error);

			if (onError) {
				onError(error);
			}
		}
	};

	const handleUploadDoc = (e: ChangeEvent<HTMLInputElement>, category: string) => {
		if (e.target.files) {
			// upload document
			handleUpload({
				category,
				file: e.target.files[0],
				onSuccess: (res) => {
					// refetch user docs
					refetchDocsUser();

					// set success banner
					setBanner({
						hasClose: true,
						message: t('alert.fileUploaded'),
						color: 'success',
					});
				},
			});
		}
	};

	const docIdentification = docsUser.find((doc: Document) => doc.category === 'IDENTIFICATION');
	const docLicense = docsUser.find((doc: Document) => doc.category === 'LICENSE');

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography color="common.white" variant="h2">
					{t('documents')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<CardUploadFile
					onUpload={(e) => handleUploadDoc(e, 'IDENTIFICATION')}
					icon={docIdentification ? 'check' : 'upload'}
					label={t('uploadIdentification')}
				/>
			</Grid>
			<Grid item xs={12}>
				<CardUploadFile
					onUpload={(e) => handleUploadDoc(e, 'LICENSE')}
					icon={docLicense ? 'check' : 'upload'}
					label={t('uploadDriversLicense')}
				/>
			</Grid>
		</Grid>
	);
};
