// react
import { useContext } from 'react';

// context
import { ContextUser } from '../../../App.context';

// mui
import { Avatar, Grid, Paper, Typography } from '@mui/material';

export const CardProfile = () => {
	// context
	const { user } = useContext(ContextUser);

	return (
		<Paper
			sx={(theme) => {
				return {
					borderBottom: `1px ${theme.palette.grey[300]} solid`,
					padding: theme.spacing(2),
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: theme.spacing(1),
					textAlign: 'center',
				};
			}}>
			<Avatar className="xlarge" />
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography variant="h4">{user?.name}</Typography>
					<Typography color="brand.grey.500">{user?.email}</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};
