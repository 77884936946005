// react
import { ReactNode, useState, useContext } from 'react';

// router
import { Navigate, useLocation } from 'react-router-dom';

// context
import { ContextBanner } from '../../../App.context';

// hooks
import { useAuth } from '../../../hooks/useAuth';
import { useIdle } from '../../../hooks/useIdle';

// mui
import { Box } from '@mui/material';

// ui
import { Header } from '../../ui/Header/Header';
import { Nav } from '../../ui/Nav/Nav';
import { Banner } from '../../ui/Banner/Banner';

// utils
import { checkToken } from '../../../utils/jwt';

export interface AuthenticatedProps {
	children: ReactNode;
}

export const Authenticated = ({ children }: AuthenticatedProps) => {
	// session
	const token = sessionStorage.getItem('token');

	// hooks
	const { logout } = useAuth();
	const location = useLocation();

	// logout after being idle for 30 mins
	useIdle({
		onIdle: () => {
			logout('/?error=expired');
		},
		timeout: 1800000,
	});

	// context
	const { banner } = useContext(ContextBanner);

	// state
	const [isNavOpen, setIsNavOpen] = useState(false);

	// vars
	const isTokenValid = checkToken(token);

	const handleOpenNav = () => {
		setIsNavOpen(true);
	};

	const handleCloseNav = () => {
		setIsNavOpen(false);
	};

	if (!isTokenValid) {
		return <Navigate to="/error" state={{ from: location }} replace />;
	}

	return (
		<Box
			sx={(theme) => {
				return {
					backgroundColor: theme.palette.brand.blue[800],
					minHeight: '100vh',
				};
			}}>
			<Header onOpenNav={handleOpenNav} />
			{banner && <Banner {...banner} />}
			<Box
				component="main"
				sx={(theme) => {
					return {
						padding: theme.spacing(3, 0),
					};
				}}>
				{children}
			</Box>
			<Nav isOpen={isNavOpen} onClose={handleCloseNav} />
		</Box>
	);
};
